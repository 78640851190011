
footer {
  background-color: transparent;
  padding: 10px 10px 10px 10px;
  width: 100%;
  align-content: center;
  border: 2px;
  border-color: black;
}

.footer-links {
  background-color: transparent;
  color: white;
  font-style: normal;
  font-size: small;
  font-weight: 600;
  text-align: center;
  padding: 5px 5px 5px 5px;
}
.footer-links:hover {
  color: black;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.copyright {
  font-style: normal;
  font-size: small;
  font-weight: 200;
  color: white;
  text-align: center;
}
