.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 8px 5px 8px;
}

.followus {
    display: flex;
    flex-direction: row-reverse;
}

#followus-grad {
    background-image: linear-gradient(rgb(245, 245, 245), rgb(185, 127, 224))
}

.debug-border {
    border: 1px dotted red;
}

.debug-border.blue {
    border-color: blue;
}

.debug-border.green {
    border-color: green;
}

