
.license-container {
  display: flex;
  flex-direction: column;
  background-color: aliceblue;
  width: 100%;
  padding: 30px 30px 30px 30px;

  color: var(--text-color);
  border-radius: 10px;
  margin: 15px 15px 15px 15px;   
}

.notice {
  padding: 5px 5px 5px 5px;
}

.list-container {
  height: 100%;  
  padding: 15px 15px 15px 15px;
}

.card-active {
  background-color: #078877;
  color: aliceblue;
}

.card-inactive {
  background-color: #2d2d2e;
  color: aliceblue;
}