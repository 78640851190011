
.carousal-img {
  height: 350px;
  border-radius: 20px 20px 0px 0px;
}

.seattle {
  background-image: url("./../../images/seattle11.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  border-radius: 10px 10px 0px 0px;
}

.carousal1 {
  background-image: url("./../../images/photo81.jpg");
  background-repeat: no-repeat;
  background-size: cover;  
  height: 350px;
  border-radius: 10px 10px 0px 0px;
}

.carousal2 {
  background-image: url("./../../images/we11.jpg");
  background-repeat: no-repeat;
  background-size: cover;  
  height: 350px;
  border-radius: 10px 10px 0px 0px;
}