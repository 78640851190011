.contactus {
 background-color: aliceblue;
  width:100%;
  padding: 30px 30px 30px 30px;  
  border-radius: 10px;

  margin: 15px 15px 15px 15px;     
}

.contactus-back {
  background-image: url('../../images/contact-photo2.jpg');
  background-repeat: no-repeat;
  background: 1px dotted white;
}

.form {
  background-image: linear-gradient(to bottom, #589ae6, #10498a); 
  border-radius: 15px;  
  padding: 15px 25px 15px 25px;  
  margin: 10px 10px 10px 10px;

  color: white;
}

.address {
  margin: 10px 0px 10px 0px;

  background-image: linear-gradient(to bottom, #589ae6, #10498a);   
  border-radius: 15px;
  color: white;
}

.link {
  color: white;
}
.link:hover {
  color: white
}
