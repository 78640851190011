
#root {
  height: 100%;
  align-content: center;
  background-image: linear-gradient(to bottom, #bff1eb, #078877);      
}
.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 8px 5px 8px;
}

.followus {
    display: flex;
    flex-direction: row-reverse;
}

#followus-grad {
    background-image: linear-gradient(rgb(245, 245, 245), rgb(185, 127, 224))
}

.debug-border {
    border: 1px dotted red;
}

.debug-border.blue {
    border-color: blue;
}

.debug-border.green {
    border-color: green;
}



.carousal-img {
  height: 350px;
  border-radius: 20px 20px 0px 0px;
}

.seattle {
  background-image: url(../../static/media/seattle11.50d2192e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  border-radius: 10px 10px 0px 0px;
}

.carousal1 {
  background-image: url(../../static/media/photo81.673fc19a.jpg);
  background-repeat: no-repeat;
  background-size: cover;  
  height: 350px;
  border-radius: 10px 10px 0px 0px;
}

.carousal2 {
  background-image: url(../../static/media/we11.154d798b.jpg);
  background-repeat: no-repeat;
  background-size: cover;  
  height: 350px;
  border-radius: 10px 10px 0px 0px;
}
.contactus {
 background-color: aliceblue;
  width:100%;
  padding: 30px 30px 30px 30px;  
  border-radius: 10px;

  margin: 15px 15px 15px 15px;     
}

.contactus-back {
  background-image: url(../../static/media/contact-photo2.8a4cc936.jpg);
  background-repeat: no-repeat;
  background: 1px dotted white;
}

.form {
  background-image: linear-gradient(to bottom, #589ae6, #10498a); 
  border-radius: 15px;  
  padding: 15px 25px 15px 25px;  
  margin: 10px 10px 10px 10px;

  color: white;
}

.address {
  margin: 10px 0px 10px 0px;

  background-image: linear-gradient(to bottom, #589ae6, #10498a);   
  border-radius: 15px;
  color: white;
}

.link {
  color: white;
}
.link:hover {
  color: white
}

.logo {
  height: 60px;
}

header {
  background-color: transparent;
  padding: 10px 10px 10px 10px;
}

footer {
  background-color: transparent;
  padding: 10px 10px 10px 10px;
  width: 100%;
  align-content: center;
  border: 2px;
  border-color: black;
}

.footer-links {
  background-color: transparent;
  color: white;
  font-style: normal;
  font-size: small;
  font-weight: 600;
  text-align: center;
  padding: 5px 5px 5px 5px;
}
.footer-links:hover {
  color: black;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.copyright {
  font-style: normal;
  font-size: small;
  font-weight: 200;
  color: white;
  text-align: center;
}


.policy {
    background-color: aliceblue;
    padding: 30px 30px 30px 30px;
    border-radius: 10px;
    color: black;
    margin: 15px 15px 15px 15px;    
}

.policy h3 {
    color: black;
    padding-bottom: 5px;
    text-align: center;
}

.policy h5 {
    color: black;
    padding-bottom: 5px;
    font-weight: bold;
    -webkit-text-decoration: solid;
            text-decoration: solid;
}


.policy a {
    color: black;
    text-decoration: none;
}


.license-container {
  display: flex;
  flex-direction: column;
  background-color: aliceblue;
  width: 100%;
  padding: 30px 30px 30px 30px;

  color: var(--text-color);
  border-radius: 10px;
  margin: 15px 15px 15px 15px;   
}

.notice {
  padding: 5px 5px 5px 5px;
}

.list-container {
  height: 100%;  
  padding: 15px 15px 15px 15px;
}

.card-active {
  background-color: #078877;
  color: aliceblue;
}

.card-inactive {
  background-color: #2d2d2e;
  color: aliceblue;
}
