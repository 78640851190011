
.policy {
    background-color: aliceblue;
    padding: 30px 30px 30px 30px;
    border-radius: 10px;
    color: black;
    margin: 15px 15px 15px 15px;    
}

.policy h3 {
    color: black;
    padding-bottom: 5px;
    text-align: center;
}

.policy h5 {
    color: black;
    padding-bottom: 5px;
    font-weight: bold;
    text-decoration: solid;
}


.policy a {
    color: black;
    text-decoration: none;
}
